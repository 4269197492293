import React, { useState, useEffect, useRef } from 'react';
import { Video, Monitor, Smartphone, Mic, MicOff, Camera, CameraOff, MessageSquare, SkipForward, LogOut, ToggleLeft, ToggleRight } from 'lucide-react';
declare global {
  interface Window {
    JitsiMeetExternalAPI: any;
  }
}

const VideoChat = () => {
  // State for the app
  const [showHomepage, setShowHomepage] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [micEnabled, setMicEnabled] = useState(true);
  const [cameraEnabled, setCameraEnabled] = useState(true);
  const [connectionStatus, setConnectionStatus] = useState('Disconnected');
  const [roomName, setRoomName] = useState('');
  const jitsiApiRef = useRef<any>(null);

  // Initialize Jitsi when starting chat
  const initializeJitsi = (containerElement: HTMLElement) => {
    const domain = 'meet.jit.si';
    const options = {
      roomName: roomName || Math.random().toString(36).substring(7),
      width: '100%',
      height: '100%',
      parentNode: containerElement,
      configOverwrite: {
        startWithAudioMuted: !micEnabled,
        startWithVideoMuted: !cameraEnabled,
        enableClosePage: true,
        disableDeepLinking: true,
        prejoinPageEnabled: false,
        enableWelcomePage: false
      },
      interfaceConfigOverwrite: {
        SHOW_JITSI_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        MOBILE_APP_PROMO: false,
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        TOOLBAR_BUTTONS: [
          'microphone', 'camera', 'chat', 'desktop', 'fullscreen', 'hangup'
        ]
      }
    };

    const api = new window.JitsiMeetExternalAPI(domain, options);
    jitsiApiRef.current = api;

    // Event handlers
    api.addEventListeners({
      participantJoined: () => {
        setConnectionStatus('Connected');
      },
      participantLeft: () => {
        setConnectionStatus('Waiting for partner...');
      },
      readyToClose: handleStop,
      audioMuteStatusChanged: ({ muted }: { muted: boolean }) => setMicEnabled(!muted),
      videoMuteStatusChanged: ({ muted }: { muted: boolean }) => setCameraEnabled(!muted)
    });
  };

  // Start chat handler
  const handleStartChat = () => {
    setShowHomepage(false);
    setConnectionStatus('Connecting...');
    const roomId = Math.random().toString(36).substring(7);
    setRoomName(roomId);
  };

  // Toggle handlers
  const toggleMic = () => {
    if (jitsiApiRef.current) {
      jitsiApiRef.current.executeCommand('toggleAudio');
      setMicEnabled(!micEnabled);
    }
  };

  const toggleCamera = () => {
    if (jitsiApiRef.current) {
      jitsiApiRef.current.executeCommand('toggleVideo');
      setCameraEnabled(!cameraEnabled);
    }
  };

  const toggleDarkMode = () => setDarkMode(!darkMode);

  // Skip partner handler
  const handleSkip = () => {
    if (jitsiApiRef.current) {
      jitsiApiRef.current.dispose();
      const newRoomId = Math.random().toString(36).substring(7);
      setRoomName(newRoomId);
      setConnectionStatus('Looking for a new partner...');
    }
  };

  // Stop chat handler
  const handleStop = () => {
    if (jitsiApiRef.current) {
      jitsiApiRef.current.dispose();
      jitsiApiRef.current = null;
    }
    setShowHomepage(true);
    setConnectionStatus('Disconnected');
  };

  // Homepage component
  const Homepage = () => (
    <div className={`flex flex-col items-center justify-center h-screen text-center p-6 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className="absolute top-4 right-4 flex items-center">
        <span className="mr-2 text-sm">{darkMode ? 'Dark' : 'Light'}</span>
        <button 
          onClick={toggleDarkMode} 
          className="focus:outline-none"
        >
          {darkMode ? <ToggleRight size={24} /> : <ToggleLeft size={24} />}
        </button>
      </div>
      
      <div className="flex items-center justify-center mb-6">
        <Video size={48} className={darkMode ? 'text-blue-400' : 'text-blue-600'} />
        <h1 className="text-3xl font-bold ml-2">Video Chat</h1>
      </div>
      
      <p className="text-lg mb-6 max-w-xl">
        Connect with random people around the world for a live video conversation.
      </p>
      
      <div className="flex mb-8">
        <div className={`mx-2 p-4 rounded-lg ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-md`}>
          <Monitor size={24} className="mx-auto mb-2 text-blue-500" />
          <p className="text-sm">Desktop ready</p>
        </div>
        <div className={`mx-2 p-4 rounded-lg ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-md`}>
          <Smartphone size={24} className="mx-auto mb-2 text-blue-500" />
          <p className="text-sm">Mobile ready</p>
        </div>
        <div className={`mx-2 p-4 rounded-lg ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-md`}>
          <MessageSquare size={24} className="mx-auto mb-2 text-blue-500" />
          <p className="text-sm">Text chat</p>
        </div>
      </div>
      
      <button
        onClick={handleStartChat}
        className={`py-3 px-8 rounded-full text-white font-bold text-lg transition transform hover:scale-105 ${
          darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'
        }`}
      >
        Start Chatting
      </button>
    </div>
  );

  // Chat Interface component with Jitsi integration
  const ChatInterface = () => {
    const jitsiContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (jitsiContainerRef.current && !jitsiApiRef.current) {
        initializeJitsi(jitsiContainerRef.current);
      }
    }, [jitsiContainerRef]);

    return (
      <div className={`h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
        <div className="grid grid-cols-3 grid-rows-3 gap-4 h-full p-4">
          {/* Connection status */}
          <div className={`col-span-3 flex items-center px-4 py-2 rounded-lg ${
            connectionStatus === 'Connected' 
              ? darkMode ? 'bg-green-800' : 'bg-green-100 text-green-800' 
              : darkMode ? 'bg-yellow-800' : 'bg-yellow-100 text-yellow-800'
          }`}>
            <div className={`w-3 h-3 rounded-full mr-2 ${
              connectionStatus === 'Connected' ? 'bg-green-500' : 'bg-yellow-500'
            }`}></div>
            <span>{connectionStatus}</span>
            <div className="ml-auto">
              <button onClick={toggleDarkMode} className="focus:outline-none">
                {darkMode ? <ToggleRight size={24} /> : <ToggleLeft size={24} />}
              </button>
            </div>
          </div>

          {/* Jitsi container */}
          <div className="col-span-3 row-span-2 relative">
            <div ref={jitsiContainerRef} className="w-full h-full rounded-lg overflow-hidden"></div>
          </div>

          {/* Controls */}
          <div className={`col-span-3 rounded-lg ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg flex items-center justify-center`}>
            <div className="flex flex-wrap justify-center space-x-4">
              <button
                onClick={toggleMic}
                className={`p-3 rounded-full ${
                  micEnabled 
                    ? 'bg-blue-500 text-white' 
                    : 'bg-red-500 text-white'
                }`}
              >
                {micEnabled ? <Mic size={20} /> : <MicOff size={20} />}
              </button>
              
              <button
                onClick={toggleCamera}
                className={`p-3 rounded-full ${
                  cameraEnabled 
                    ? 'bg-blue-500 text-white' 
                    : 'bg-red-500 text-white'
                }`}
              >
                {cameraEnabled ? <Camera size={20} /> : <CameraOff size={20} />}
              </button>
              
              <button
                onClick={handleSkip}
                className="p-3 rounded-full bg-yellow-500 text-white"
              >
                <SkipForward size={20} />
              </button>
              
              <button
                onClick={handleStop}
                className="p-3 rounded-full bg-red-500 text-white"
              >
                <LogOut size={20} />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="h-screen overflow-hidden">
      {showHomepage ? <Homepage /> : <ChatInterface />}
    </div>
  );
};

export default VideoChat; 